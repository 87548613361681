/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { userInstance } from '../../config/axios';
import toastMessage from '../../functions/toastMessage';

function SubscribeCheckOutForm({
  returnPopUp,
  handleAction,
  handleFormModal,
  clientData,
  amountDisplay,
}) {
  console.log(
    'clientData===>',
    clientData,
    'Subscription====>',
    clientData.duration,
    'amountDisplay==>',
    amountDisplay,
  );
  // collect data from the user
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [priceId, setPriceId] = useState('');
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  // const navigate;
  // const SubscriptionPlanArr = [
  //   { price: '264.00', priceId: 'price_1Pt8EGJYCAG32lQBTIe9dOps' },
  //   { price: '462.00', priceId: 'price_1Prv6qP0R6JGt9iCdwgMvF5P' },
  //   { price: '594.00', priceId: 'price_1PrvIFP0R6JGt9iCYcXupjtT' },
  //   { price: '324.00', priceId: 'price_1PrwyaP0R6JGt9iCaHBVxx8U' },
  //   { price: '582.00', priceId: 'price_1Prx3QP0R6JGt9iCmxulgSxk' },
  //   { price: '774.00', priceId: 'price_1PrxDKP0R6JGt9iCHiVcQugc' },
  //   { price: '384.00', priceId: 'price_1PrxJAP0R6JGt9iCH9WReyHD' },
  //   { price: '702.00', priceId: 'price_1PrxLwP0R6JGt9iCJDmv8PEF' },
  //   { price: '954.00', priceId: 'price_1PrxO0P0R6JGt9iC31ngR6IR' },
  //   { price: '444.00', priceId: 'price_1PrxPWP0R6JGt9iCsDAweXdL' },
  //   { price: '822.00', priceId: 'price_1PrxSvP0R6JGt9iCzxGXZFQW' },
  //   { price: '1134.00', priceId: 'price_1PrxYAP0R6JGt9iChYlIospz' },
  //   { price: '504.00', priceId: 'price_1PrxZfP0R6JGt9iCje5vzgDH' },
  //   { price: '942.00', priceId: 'price_1PrxbrP0R6JGt9iC70cBMXdk' },
  //   { price: '1314.00', priceId: 'price_1Prxd8P0R6JGt9iCibdvsszB' },
  //   { price: '564.00', priceId: 'price_1Prxl7P0R6JGt9iCBEz1DrYO' },
  //   { price: '624.00', priceId: 'price_1Prxq9P0R6JGt9iCdrDLyhCI' },
  //   { price: '684.00', priceId: 'price_1PrxsPP0R6JGt9iC0D10rcaj' },
  //   { price: '1062.00', priceId: 'price_1Pry18P0R6JGt9iCqA48csMA' },
  //   { price: '1182.00', priceId: 'price_1Pry36P0R6JGt9iCtkmoQqLc' },
  //   { price: '1302.00', priceId: 'price_1Pry61P0R6JGt9iCQ3kUOwqW' },
  //   { price: '1,494.00', priceId: 'price_1PryJjP0R6JGt9iCJUjwT0WL' },
  //   { price: '1674.00', priceId: 'price_1PryOGP0R6JGt9iC4sExbBXU' },
  //   { price: '1,854.00', priceId: 'price_1PryQhP0R6JGt9iC6vwG5V0u' },
  // ];

  const SubscriptionPlanArr = [
    { price: '264.00', priceId: 'price_1Pt8EGJYCAG32lQBTIe9dOps' },
    { price: '462.00', priceId: 'price_1PtRFmJYCAG32lQBNCZjVDPE' },
    { price: '594.00', priceId: 'price_1PtRHCJYCAG32lQBlQ68Ulnk' },
    { price: '324.00', priceId: 'price_1PtRIxJYCAG32lQBeASMee1D' },
    { price: '582.00', priceId: 'price_1PtRKNJYCAG32lQBsKjLRXdn' },
    { price: '774.00', priceId: 'price_1PtRL4JYCAG32lQBKrFE3m5e' },
    { price: '384.00', priceId: 'price_1PtRMEJYCAG32lQBjlWi73Ne' },
    { price: '702.00', priceId: 'price_1PtRN5JYCAG32lQBPfXG87kw' },
    { price: '954.00', priceId: 'price_1PtRNvJYCAG32lQBmxcD52Df' },
    { price: '444.00', priceId: 'price_1PtROxJYCAG32lQBo3a516Z3' },
    { price: '822.00', priceId: 'price_1PtRQRJYCAG32lQBIa1JIigH' },
    { price: '1134.00', priceId: 'price_1PtRQyJYCAG32lQBocjDN38S' },
    { price: '504.00', priceId: 'price_1PtRS3JYCAG32lQBB6B6NbEc' },
    { price: '942.00', priceId: 'price_1PtRT1JYCAG32lQBMZ6xlxHG' },
    { price: '1314.00', priceId: 'price_1PtRTnJYCAG32lQBRmRw54bc' },
    { price: '564.00', priceId: 'price_1PtRWyJYCAG32lQB5WHX1HN0' },
    { price: '624.00', priceId: 'price_1PtRcpJYCAG32lQBnfptcjey' },
    { price: '684.00', priceId: 'price_1PtRdNJYCAG32lQBBTbxBz2M' },
    { price: '1062.00', priceId: 'price_1PtRgxJYCAG32lQBwjgFa5MY' },
    { price: '1182.00', priceId: 'price_1PtRilJYCAG32lQBsDCoCuAZ' },
    { price: '1302.00', priceId: 'price_1PtRjXJYCAG32lQBoROBrttf' },
    { price: '1,494.00', priceId: 'price_1PtRkJJYCAG32lQBmQtfoBaS' },
    { price: '1674.00', priceId: 'price_1PtRl8JYCAG32lQBsPGGmSjn' },
    { price: '1,854.00', priceId: 'price_1PtRlyJYCAG32lQB4XyaiYVc' },
  ];

  const matchingPlan = SubscriptionPlanArr.find(
    (plan1) => Number(plan1.price) === amountDisplay,
  );

  const matchedpriceId = matchingPlan ? matchingPlan.priceId : null;
  console.log('matchedpriceId==>', matchedpriceId);

  const options = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '13px',
        '::placeholder': {
          color: '#000',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#000',
      },
    },
  };

  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  console.log('client?????', clientData);

  let clientName = `${clientData.firstname} ${clientData.lastname}`;
  let clientEmail = clientData.email;
  // main function
  const createSubscription = async () => {
    try {
      // create a payment method
      setLoader(true);
      const paymentMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: elements?.getElement(CardElement),
        billing_details: {
          name: clientName,
          email: clientEmail,
        },
      });
      // console.log('paymentMethod=>>>', paymentMethod?.paymentMethod.card.last4, 'CardElement===>', CardElement);

      const payload = {
        paymentMethod: paymentMethod?.paymentMethod?.id,
        name: clientName,
        email: clientEmail,
        priceId:
          matchedpriceId === null || clientData?.subscriptionStatus === 'trial'
            ? 'price_1Prv1XP0R6JGt9iCfmA7SWo0'
            : matchedpriceId,
        subscriptionStatus: clientData?.subscriptionStatus,
      };

      const response = await userInstance().post(
        '/user/create-subscription',
        payload,
      );
      console.log(
        'response.clientSecret,==>',
        response,
        'response.status==>',
        response.status,
        'clientData?.subscriptionStatus====>',
        clientData?.subscriptionStatus,
      );
      if (
        response.status === 200
        && clientData?.subscriptionStatus === 'trial'
      ) {
        console.log('Success! Check your email for the invoice.');
        const responseUserTrail = await userInstance().post(
          '/user/registerSubscriber',
          clientData,
        );
        console.log('res', responseUserTrail);
        toastMessage(
          'success',
          'User Register Successfully',
        );
        handleAction(returnPopUp);
        handleFormModal();
      }
      const confirmPayment = await stripe?.confirmCardPayment(
        response.data.clientSecret,
      );
      // console.log('confirmPayment===>', confirmPayment);
      if (confirmPayment?.error) {
        console.log(confirmPayment.error.message);
      } else {
        console.log('Success! Check your email for the invoice.');
        const responseUser = await userInstance().post(
          '/user/registerSubscriber',
          clientData,
        );
        toastMessage('success', 'User Register Successfully');
        setLoader(false);
        handleAction(returnPopUp);
        handleFormModal();
        // history.push('/subscriptions');
      }
    } catch (error) {
      console.log('error', error);
      setLoader(false);
      toastMessage('error', 'Please Enter the Valid Card Details.');
    }
  };

  return (
    <div className="grid gap-4 m-auto mt-3">
      <CardElement id="card-element" options={options} />
      {!loader ? (
        <Button
          className="btn-save"
          type="submit"
          onClick={createSubscription}
          disabled={!stripe}
        >
          Subscribe
        </Button>
      )
        : (
          <Button
            className="btn-save pb-3"
          // type="submit"
          >
            {/* <div className="video-loader1 pb-5"> */}
            <Spinner animation="border" size="sm" />
            {/* </div> */}

          </Button>
        )}

    </div>
  );
}

export default SubscribeCheckOutForm;

SubscribeCheckOutForm.propTypes = {
  returnPopUp: PropTypes.objectOf.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  clientData: PropTypes.objectOf.isRequired,
  amountDisplay: PropTypes.string.isRequired,
};
